<template>
<div>
 <el-dialog
    :title="dialogTitle"
    :visible.sync="show"
    width="940px"
    :close-on-click-modal="false"
    @close="handelClose"
  >
    <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="120px" v-loading="dialogLoading" class="form-box">
      <el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="所属项目" :required="!isRead" prop="projectId">
              <el-select
                v-model="ruleForm.projectId"
                placeholder="请选择"
                style="width:330px"
                :disabled="isRead"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.orgId"
                  :label="item.orgName"
                  :value="item.orgId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="11" :offset="2">
            <el-form-item label="工点/部位" :required="!isRead" prop="location">
              <el-input v-model="ruleForm.location" style="width:330px" placeholder="请输入" :disabled="isRead"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="隐患照片">
              <div v-if="ruleForm.sysFileInfos&&ruleForm.sysFileInfos.length||!isRead"> 
                <upload-multiple-image  :default-list="defaultImage"  @get-file-data="getImageData"   :is-disabled="isDisabled"></upload-multiple-image>
              </div>
              <div v-else>无</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="存在的问题"  :required="!isRead" prop="problem">
              <el-input
                v-model="ruleForm.problem"
                type="textarea"
                placeholder="请输入"
                :maxlength="200"
                :disabled="isRead"
                resize="none"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="整改建议"  :required="!isRead" prop="suggestion">
              <el-input
                v-model="ruleForm.suggestion"
                type="textarea"
                placeholder="请输入"
                :maxlength="200"
                :disabled="isRead"
                resize="none"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="隐患级别"  :required="!isRead" prop="hiddenLevelId">
              <el-select v-model="ruleForm.hiddenLevelId" placeholder="请选择" style="width:330px" :disabled="isRead" @change="changeDangerLevelList">
                <el-option
                  v-for="item in dangerLevelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="隐患类型"  :required="!isRead" prop="hiddenTypeId">
              <el-select v-model="ruleForm.hiddenTypeId" placeholder="请选择" style="width:330px" :disabled="isRead" @change="changeDangerTypeList">
                <el-option
                  v-for="item in dangerTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="隐患类别" :required="!isRead" prop="hiddenCategoryId">
              <el-select v-model="ruleForm.hiddenCategoryId" placeholder="请选择" style="width:330px" :disabled="isRead" @change="changeDangerCategoryList">
                <el-option
                  v-for="item in dangerCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="排查时间"  :required="!isRead" prop="investigationTime">
              <el-date-picker
                style="width:330px"
                value-format="yyyy-MM-dd"
                v-model="ruleForm.investigationTime"
                type="date"
                placeholder="请选择日期"
                :disabled="isRead"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="排查人" :required="!isRead" prop="investigationPerson">
               <el-input
                style="width:330px"
                v-model="ruleForm.investigationPerson"
                :disabled="isRead"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row >
           <el-col :span="12">
            <el-form-item label="整改期限"  :required="!isRead" prop="limitTime">
              <el-date-picker
               style="width:330px"
               value-format="yyyy-MM-dd"
                v-model="ruleForm.limitTime"
                type="date"
                placeholder="请选择日期"
                :disabled="isRead||!(ruleForm.investigationTime)"
                :picker-options="pickerOptionsLast"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="isRead">
            <el-form-item label="隐患状态">
             <el-input
                style="width:330px"
                :value="ruleForm.status|hiddenStaus"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
      <!--记录详情-->
      <el-row v-if="ruleForm.rectificationRecord&&ruleForm.rectificationRecord.length">
        <div v-for="(item,index) in ruleForm.rectificationRecord" :key="item.id">
          <div class="record-title">
            <span v-if="ruleForm.rectificationRecord.length>2">第{{(index+1)/2|titleIndex}}次</span>{{ item.operation===1?'整改':'复核'}}记录
            </div>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="item.operation===1?'整改描述':'复核描述'" label-width="120px">
                <el-input v-model="item.description" type="textarea" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item :label="item.operation===1?'整改时间':'复核时间'" label-width="120px">
                <el-input v-model="item.operatingTime" disabled></el-input>
              </el-form-item>
            </el-col>
            <!-- 复核记录才有复核结果 -->
             <el-col :span="11" :offset="1" v-if="item.operation===2">
              <el-form-item label="复核结果" label-width="120px">
                <el-input disabled :value="item.regular===2?'不合格':'合格'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
               <el-form-item :label="item.operation===1?'整改人':'复核人'" label-width="120px">
                <el-input v-model="item.investigationPerson" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="item.operation===1?'整改图片':'复核图片'" label-width="120px">
                <div class="image-box" v-if="item.sysFileInfos.length">
                  <img :src="imageSingle.filePath" alt="图片" v-for="imageSingle in item.sysFileInfos" :key="imageSingle.fileId" @click="handlePictureCardPreview(imageSingle.filePath)">
                </div>
                <div v-else>无</div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-row>
      </el-row>
    </el-form>
    <div slot="footer" v-if="$check('health-hidden-danger_edit')">
      <span v-if="!isRead">
        <el-button @click="resetForm">取消</el-button>
        <el-button @click="submitForm" type="primary" :loading="btnLoading">确定</el-button>
      </span>
      <span v-else-if="ruleForm.status===1"><!-- 待整改 -->
        <span v-if="ruleForm.rectificationRecord&&!ruleForm.rectificationRecord.length"
          ><!-- 隐患状态为待整改（未整改） -->
          <el-button type="danger" @click="handelDelete">删除隐患</el-button>
          <el-button @click="handelUpdate">修改隐患</el-button>
        </span>
        <!-- 隐患状态为待整改（整改不合格） -->
        <el-button type="primary" @click="hendelHiddenDanger" style="margin-left:10px">点击整改</el-button>
      </span>
      <span v-else-if="ruleForm.status===2">
         <el-button type="primary" style="margin-left:10px" @click="hendelHiddenDanger">点击复核</el-button>
      </span>
    </div>
  </el-dialog>
  <!-- 待复核或者待整改dialog -->
  <el-dialog :title="`${formLabel}隐患`"
    :visible.sync="reviewRectificateShow"
    width="750px"
    :close-on-click-modal="false"
    @close="handelCloseDialog"
    >
    <el-form :model="reviewRectificateForm"  :rules="reviewRectificateRules" ref="reviewRectificateForm" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="`${formLabel}描述`" prop="description">
             <el-input v-model="reviewRectificateForm.description" type="textarea"  placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item :label="`${formLabel}时间`" prop="operatingTime">
              <el-date-picker
                v-model="reviewRectificateForm.operatingTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item :label="`${formLabel}人`" prop="investigationPerson">
              <el-input
                style="width:295px"
                v-model="reviewRectificateForm.investigationPerson"
                :disabled="isRead"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
          <!-- 隐患待复核才显示 -->
          <el-row>
          <el-col :span="10" v-if="status===2">
            <el-form-item label="复核结果" prop="regular">
              <el-radio-group v-model="reviewRectificateForm.regular">
              <el-radio :label="1">合格</el-radio>
              <el-radio :label="2">不合格</el-radio>
            </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="`${formLabel}图片`">
              <upload-multiple-image  :default-list="defaultList"  @get-file-data="getFileData"></upload-multiple-image>
            </el-form-item>
          </el-col>
        </el-row>
    </el-form>
     <div slot="footer">
        <el-button  @click='handelCloseDialog'>取消</el-button>
        <el-button  type="primary" @click="handleDanger" :loading="btnLoading">确定</el-button>
    </div>
  </el-dialog>
  <!-- 预览图片 -->
  <el-dialog :visible.sync="imageVisible">
      <img width="100%" :src="dialogImageUrl" alt="预览大图" />
    </el-dialog>
</div>
 
</template>

<script>
import { ceil,cloneDeep } from 'lodash';
import { validateRules } from './validateRules.js';
import UploadMultipleImage from 'components/Upload/UploadMultipleImage.vue';
import {  addDanger,updateHiddenDanger,deleteHiddenDanger,addRectificationRecord } from 'api/general-user/prevention-control/danger-treat';
export default {
  name: 'listDisplayDialog',
  mixins: [validateRules],
  components: { UploadMultipleImage },
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    isRead: {
      //是否可读(查看详情为true)
      type: Boolean,
      required: true,
      default: false
    },
     //这些都是父组件传下来的下拉数据（这里没调取接口，感觉太臃肿了，采取的是传值）
    projectList:{
       //所属项目部
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    dangerLevelList: {
      //隐患级别
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    dangerTypeList: {
      //隐患类型
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    dangerCategoryList: {
      //隐患类别
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
       //选择日期之后
      pickerOptionsLast:{
      },
      dialogLoading: false,
      btnLoading:false,
      imageVisible:false,
      isDisabled:false,//上传组件是否隐藏，查看详情为true
      dialogImageUrl:'',
      ruleForm: {
      },
      //这里赋值一份
      ruleDeepForm:{},
      show: this.dialogVisible,
      //新增和编辑的图片上传默认列表
      defaultImage:[],
      //下面都是复核或者整改dialog
      reviewRectificateShow:false,
      reviewRectificateForm:{
        hiddenId:'',
        operation:'',//1待整改，2待复核
      },
      reviewRectificateRules: {
        description: [ 
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 0, max: 500, message: '最大字符为500' }
        ],
        operatingTime: [ 
          { required: true, message: '请选择日期', trigger: 'change' },
        ],
        regular: [ 
          { required: true, message: '请选择复核结果', trigger: 'change' },
        ],
      },
      defaultList:[],    
      //记录列表中隐患的状态
      status:'',//这里为啥要多个字段来记录，因为点击复核或者整改，当前dialog关闭，值也就没有了
    };
  },
  methods: {
    //表单回显查看详情
    initFormDetail(row){
      this.defaultImage=[];
      this.dialogLoading=true;
      this.ruleForm=row;
      this.ruleDeepForm=cloneDeep(row);
      //这里表单下拉回显用name,不用id
      this.ruleForm.projectId=row.projectDepartmentName;
      this.ruleForm.sectionId=row.sectionName;
      this.ruleForm.hiddenTypeId=row.hiddenTypeName;
      this.ruleForm.hiddenCategoryId=row.hiddenCategoryName;
      //    
      this.status=row.status;
      this.reviewRectificateForm.hiddenId=row.id//待整改或者待复核要用这个字段
      this.reviewRectificateForm.operation=row.status//待整改或者待复核要用这个字段
      this.isDisabled=true;
      //这里图片上传组件的回显
      if(row.sysFileInfos.length){
        row.sysFileInfos.forEach(item=>{
          let obj={
            name:item.fileOriginName,
            url:item.filePath,
            fileId:item.fileId
          }
          this.defaultImage.push(obj);
        })
      }
      setTimeout(()=>{
        this.dialogLoading=false
      },500)
    },
    handlePictureCardPreview(imageUrl){//预览图片
      this.dialogImageUrl=imageUrl;
      this.imageVisible = true;
    },
    changeDangerLevelList(id){
      let obj = {};
      obj = this.dangerLevelList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.hiddenLevelName = obj.name;
    },
    changeDangerTypeList(id){
      let obj = {};
      obj = this.dangerTypeList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.hiddenTypeName = obj.name;
    },
    changeDangerCategoryList(id){
      let obj = {};
      obj = this.dangerCategoryList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.hiddenCategoryName = obj.name;
    },
    //图片上传组件返回的值(待复核或者待整改)
    getFileData(fileId){
      this.reviewRectificateForm.pictureId=fileId.join(',');
    },
    getImageData(fileId){//新增或者编辑
      this.ruleForm.pictureId=fileId.join(',');
    },
    //点击复核或者点击整改
    hendelHiddenDanger(){
      this.$emit('update:dialogVisible', false);
      this.reviewRectificateShow=true
    },
    //处理隐患(整改或者复核)
    handleDanger(){
       this.$refs.reviewRectificateForm.validate(valid => {
        if (valid) {
          this.addRectificationRecord()
        }
      });
    },
    handelCloseDialog(){
      this.defaultList=[];
      this.reviewRectificateForm={}
      this.reviewRectificateShow=false;
      this.$refs.reviewRectificateForm.resetFields();
    },
    //下面的是新增或者查看详情的事件
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(this.ruleForm.id){
            console.log(this.ruleForm)
            this.updateHiddenDanger()
          }else{
            console.log(this.ruleForm)
            this.addDanger()
          }
        }
      });
    },
     //新增接口
    addDanger() {
      this.btnLoading=true;
      addDanger(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.btnLoading=false;
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', true);
        }
      }).catch(()=>{
         this.btnLoading=false;
      })
    },
    //编辑接口
    updateHiddenDanger(){
       updateHiddenDanger(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message.success('编辑成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    //删除接口
    deleteHiddenDanger(){
      deleteHiddenDanger({ id: this.ruleForm.id }).then(res=>{
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      })
    },
    //添加整改或者复核记录接口
    addRectificationRecord(){
       this.btnLoading=true;
       addRectificationRecord(this.reviewRectificateForm).then(res=>{
        if (res.code === 200) {
          this.btnLoading=false;
          this.$message.success('操作成功');
          this.reviewRectificateShow=false;
          this.$emit('handel-success', false);
        }
      }).catch(()=>{
        this.btnLoading=false;
      })
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.$emit('update:isRead', false);
      this.ruleForm = {};
      this.ruleDeepForm={};
      this.isDisabled=false
      this.defaultImage=[];
      this.$refs.ruleForm.resetFields();
    },
    //修改事件
    handelUpdate() {
      this.dialogLoading = true;
      this.isDisabled=false;
      this.ruleForm=this.ruleDeepForm;
      this.$emit('update:dialogTitle', '修改隐患');
      this.$emit('update:isRead', false);
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500); 
    },
    //删除事件
    handelDelete() {
      this.$confirm('请确认是否删除该隐患?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteHiddenDanger()
      });
    },
  },
  computed:{
    //待复核状态显示复核，否则显示整改
    formLabel(){
      return this.status==2?'复核':'整改'
    },
  },
  filters:{
    titleIndex(index){
       return ceil(index)
    },
    //状态
    hiddenStaus(status){
      switch(status){
        case 1:
          return '待整改';
        case 2:
          return '待复核';
        default:
          return '已消除'
      }
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    },
    'ruleForm.investigationTime'(val){
      this.pickerOptionsLast={
        disabledDate(time) {
          return time.getTime() < Date.parse(val);
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
/deep/.el-textarea__inner {
  height: 96px;
}
.record-title {
  height: 40px;
  line-height: 40px;
  background: #f3f6fe;
  font-weight: 700;
  color: #303133;
  padding-left: 18px;
  margin-bottom: 16px;
}
.form-box{
  max-height:800px;
  overflow-y:auto;
  overflow-x:hidden
}
.image-box{
  display: flex;
  img{
    width:80px;
    height:80px;
    display:block;
    margin-right: 6px;
    cursor: pointer;
  }
}
</style>
