export const validateRules = {
  data() {
    var validateProjectId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validateLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入工点/部位'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入存在的问题'));
      } else if (value.length > 200) {
        return callback(new Error('最大字符为200'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入整改建议'));
      } else if (value.length > 200) {
        return callback(new Error('最大字符为200'));
      }
      callback();
    };
    var validateHiddenLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择隐患级别'));
      }
      callback();
    };
    var validateHiddenTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择隐患类型'));
      }
      callback();
    };
    var validateHiddenCategoryId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择隐患类别'));
      }
      callback();
    };
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入排查人'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateInvestigationTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择排查日期'));
      }
      callback();
    };
    var validateInvestigationTimeLast = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择限期日期'));
      }
      callback();
    };

    return {
      rules: {
        projectId: [{ validator: validateProjectId, trigger: 'change' }],
        location: [{ validator: validateLocation }],
        problem: [{ validator: validateProblem }],
        suggestion: [{ validator: validateSuggestion }],
        hiddenLevelId: [{ validator: validateHiddenLevelId, trigger: 'change' }],
        hiddenTypeId: [{ validator: validateHiddenTypeId, trigger: 'change' }],
        hiddenCategoryId: [{ validator: validateHiddenCategoryId, trigger: 'change' }],
        investigationTime: [{ validator: validateInvestigationTime }],
        limitTime: [{ validator: validateInvestigationTimeLast }],
        investigationPerson: [{ validator: validateName}]
      }
    };
  }
};
