<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="模糊查询">
          <el-input v-model="queryForm.problem" placeholder="请输入主要问题" style="width:280px"></el-input>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.ids" placeholder="请选择所属项目部" multiple collapse-tags>
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排查日期">
          <el-date-picker
          v-model="queryForm.checkTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          >
         </el-date-picker>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select v-model="queryForm.hiddenLevelIds" placeholder="请选择隐患级别" multiple collapse-tags>
            <el-option v-for="item in dangerLevelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.statusList" placeholder="请选择隐患状态" multiple collapse-tags>
            <el-option v-for="item in dangerStatusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.hiddenTypeIds" placeholder="请选择隐患类型" multiple collapse-tags>
            <el-option v-for="item in dangerTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.hiddenCategoryIds" placeholder="请选择隐患类别" multiple collapse-tags>
            <el-option v-for="item in dangerCategoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <operate-button>
      <template #default>
        <el-button
          v-if="$check('health-hidden-danger_add')"
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="addDangerSource"
          >新增隐患</el-button
        >
        <div style="display:flex;align-items:center">
          <a href="./隐患导入模版.xlsx" style="color:#005aab" v-if="$check('health-hidden-danger_download')">下载导入模板</a>
          <importUpload
            v-if="$check('health-hidden-danger_import')"
            class="export-btn"
            @get-file="getFile"
            v-loading.fullscreen="importLoading"
            element-loading-text="拼命导入中"
            element-loading-background="rgba(0, 0, 0, 0.1)"
          ></importUpload>
          <el-button
            v-if="$check('health-hidden-danger_notice')"
            class="export-btn"
            @click="handleDangerNotice"
            :disabled="btnDisabeld"
            v-loading.fullscreen="exportLoading"
            element-loading-text="拼命导出中"
            element-loading-background="rgba(0, 0, 0, 0.1)"
            >导出隐患整改通知书</el-button
          >
        </div>
      </template>
    </operate-button>
    <!-- 列表 -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tabelLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed align="center"> </el-table-column>
        <el-table-column label="序号" width="55" fixed align="center" type="index">
        </el-table-column>
        <el-table-column prop="problem" label="存在的问题" fixed align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="projectDepartmentName" label="所属项目部" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        </el-table-column>
        <el-table-column prop="location" label="工点/部位" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="investigationTime" label="排查时间" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column
          prop="hiddenLevelName"
          label="隐患级别"
          align="center"
          show-overflow-tooltip
          width="120"
          sortable
        >
        </el-table-column>
         <el-table-column
          prop="hiddenTypeName"
          label="隐患类型"
          align="center"
          show-overflow-tooltip
          width="120"
          sortable
        >
        </el-table-column>
         <el-table-column
          prop="hiddenCategoryName"
          label="隐患类别"
          align="center"
          show-overflow-tooltip
          width="120"
          sortable
        >
        </el-table-column>
        <el-table-column prop="status" label="隐患状态" align="center" show-overflow-tooltip width="120" sortable>
          <template slot-scope="{ row }">
            <span>{{ row.status | showDangerStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" fixed="right" width="120" align="center">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="viewDetail(row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- dialog -->
    <list-display-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :danger-type-list="dangerTypeList"
      :danger-level-list="dangerLevelList"
      :danger-category-list="dangerCategoryList"
      :project-list="projectList"
      :echo-form-data="echoFormData"
      @handel-success="handelSuccess"
    ></list-display-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { selectList } from '../selectList.js';
import { exportFun } from '@/utils/export.js';
import {getDataRangByNature } from 'api/common'
import {
  getHiddenDangerPage,
  exportHiddenDanger,
  hiddenDangerImportData
} from 'api/general-user/prevention-control/danger-treat';
import importUpload from 'components/Upload/ImportUpload';
import listDisplayDialog from './listDisplayDialog';
import operateButton from 'views/pages/general-user/security-pages/prevention-control/_components/operateButton';
export default {
  mixins: [selectList],
  components: {
    importUpload,
    listDisplayDialog,
    operateButton
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dialogVisible: false,
      dialogTitle: '',
      isRead: false,
      echoFormData: null,
      tabelLoading: false,
      //导入loading
      importLoading: false,
      //导出
      exportLoading: false,
      queryForm: {
        ids: [],
        hiddenLevelIds: [],//隐患等级
        statusList: [],//隐患状态1待整改 2待复核 3已消除
        hiddenTypeIds:[],//隐患类型
        hiddenCategoryIds:[],//隐患类别
        problem: '',
        checkTime:'',//排查时间（时间范围 以逗号隔开）
        pageNo: 1,
        pageSize: 10
      },
      dangerStatusList: [
        //隐患状态
        {
          name: '待整改',
          id: 1
        },
        {
          name: '待复核',
          id: 2
        },
        {
          name: '已消除',
          id: 3
        }
      ],
      projectList:[],
      total: 0,
      tableData: [],
      //导出需要的ids
      ids: [],
      btnDisabeld: true
    };
  },
  methods: {
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.ids.length);
    },
    //导入
    getFile(file) {
      this.importLoading = true;
      let fileData = new FormData();
      fileData.append('file', file);
      hiddenDangerImportData(fileData)
        .then(res => {
          if (res.code === 200 && !res.data.fileInfo) {
            this.importLoading = false;
            this.$message({
              type: 'success',
              message: res.data.data
            });
            this.getHiddenDangerPage();
          } else {
            this.importLoading = false;
            this.$message({
              message: res.data.data,
              type: 'error',
              duration: 0,
              offset: 130,
              showClose: true
            });
            setTimeout(() => {
              let filePath = res.data.fileInfo.filePath;
              // 直接使用window.open把url放进去后就可以了
              window.open(filePath);
            }, 200);
          }
        })
        .catch(() => {
          this.importLoading = false;
        });
    },
    //导出隐患整改通知书
    handleDangerNotice() {
      this.exportLoading = true;
      exportHiddenDanger({
        ids: this.ids
      })
        .then(res => {
          if (res.status === 200) {
            exportFun(res);
            this.exportLoading = false;
            this.$message({
              type: 'success',
              message: '导出数据成功',
              onClose: () => {
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    //分页查询
    getHiddenDangerPage() {
      this.tabelLoading = true;
      let pamers=cloneDeep(this.queryForm);
      if( pamers.checkTime){
        pamers.checkTime= pamers.checkTime.join(',')
      }
      getHiddenDangerPage(pamers).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          const { total, records } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    //获取项目部
    getDataRangByNature(){
      getDataRangByNature({orgId:''}).then(res=>{
        if(res.code===200){
          this.projectList=res.data;
        }
      })
    },
    addDangerSource() {
      //新增危险源
      this.dialogVisible = true;
      this.dialogTitle = '新增隐患';
    },
    viewDetail(row) {
      //查看详情
      this.isRead = true;
      this.dialogVisible = true;
      const obj = cloneDeep(row);
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
      this.dialogTitle = '查看详情';
    },
    handelSuccess(Boolean) {
      //true为返回列表第一页，否则为编辑，在当前页
      if (Boolean) {
        this.queryForm.pageNo = 1;
      }
      this.getHiddenDangerPage();
      //刷新列表
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getHiddenDangerPage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getHiddenDangerPage();
    },
    handelSearch() {
      //搜索
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getHiddenDangerPage();
    },
    handelReset() {
      //重置
      this.queryForm = {};
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.$refs.multipleTable.clearSort();
      this.getHiddenDangerPage();
    }
  },
  filters: {
    showDangerStatus(status) {
      switch (status) {
        case 1:
          return '待整改';
        case 2:
          return '待复核';
        default:
          return '已消除';
      }
    }
  },
  created() {
    this.getHiddenDangerPage();
    this.configDetailList();
    this.getDataRangByNature();
  }
};
</script>

<style scoped lang="less">
.search-list {
  border-bottom: 1px solid #dcdfe7;
}
.export-btn {
  margin-left: 16px;
}
</style>
